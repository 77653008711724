import React, { useEffect } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { reCaptchaKey } from "../../../components/Variables"
import { downloadReportView } from "../../../components/Statuses/Statuses"

import Layout from "../../../components/Layout/Layout"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import HeaderImage from "../../../assets/img/new/tlo-serch-01.webp"
import HubSpotForm from "../../../components/HubSpotForm"

import { devMode } from "../../../components/Variables"

import "../../../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const Pobierz = props => {
  const id = props.params.id
  const { t } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage("pl")
    downloadReportView(id)
  }, [id])

  return (
    <Layout
      seo={{
        title: "Twój raport audyt SEO • Pobierz wynik audytu strony za darmo • WeNet",
        description:
          "Wypełnij formularz i pobierz za darmo raport audyt SEO • Sprawdź, jak możesz ulepszyć swoją stronę WWW",
        hreflangs: {
          pl: "audyt/" + id + "/pobierz/",
          en: "en/audit/" + id + "/download/",
          de: "de/audit/" + id + "/herunterladen/",
        },
        robots: "noindex, nofollow",
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: "/audyt/" + id + "/pobierz",
                text: t("link-text-download-report"),
              },
            ]}
          />
        </div>
        <h1 className="title text-center">Pobierz raport z audytu SEO za darmo</h1>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="dowload-text hubspot-success-hide" style={{ marginTop: "30px" }}>
                Po wypełnieniu poniższego formularza na podany adres e-mail zostanie wysłany kompletny, rozbudowany
                raport z audytu SEO Twojej strony w formacie PDF. Raport uzupełniony jest o merytoryczne opisy każdego
                sprawdzanego kryterium, co pomoże Ci lepiej zrozumieć oczekiwania wyszukiwarki i zoptymalizować swoją
                stronę WWW.
              </p>
            </div>
            <div className="col-md-12">
              <div style={{ maxWidth: "535px", margin: "40px auto 0 auto" }}>
                <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                  <HubSpotForm
                    formId={
                      devMode === "true"
                        ? "3c45cc72-4781-46e7-97ed-47fce3bfcf2b"
                        : "e1a7c9e4-da37-4e29-8b13-c504b9411f19"
                    }
                    formType="download"
                    auditId={id}
                    userWWW={props?.location?.state?.userWWW ? props.location.state.userWWW : undefined}
                  />
                </GoogleReCaptchaProvider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Pobierz
